import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

import HeroImage from "../images/hero.jpg";

import Header from "../components/Header";
import Footer from "../components/Footer";
import WorkCard from "../components/Workcard";

const MainContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

const WorksContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 32px;
  @media screen and (max-width: 970px) {
    grid-template-columns: 1fr;
  }
`;

const IndexContainer = styled.div`
  padding: 0 32px;
  .more {
    color: white;
  }
  .title {
    margin-top: 40px;
  }
  .hero {
    height: 40vh;
    @media screen and (max-width: 970px) {
      height: 30vh;
    }
    border-radius: 16px;
    overflow: hidden;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM D, YYYY")
              genre
              shortDescription
              thumbnailPath {
                childImageSharp {
                  gatsbyImageData
                }
              }
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const worksData = data.allMarkdownRemark.edges.slice(0, 6);

  return (
    <>
      <MainContents>
        <WidthLimiter>
          <Header pageTitle="Home"></Header>
          <IndexContainer>
            <div className="hero">
              <img src={HeroImage} alt="Juhyoung Lee"></img>
            </div>
            <div className="title">
              <h2>
                Recent works
                <Link className="more" to="/works">
                  <small>...more</small>
                </Link>
              </h2>
            </div>
            <WorksContainer>
              {worksData.map((work) => (
                <WorkCard
                  key={work.node.id}
                  title={work.node.frontmatter.title}
                  genre={work.node.frontmatter.genre}
                  description={work.node.frontmatter.shortDescription}
                  imgPath={
                    work.node.frontmatter.thumbnailPath.childImageSharp
                      .gatsbyImageData
                  }
                  slug={work.node.fields.slug}
                ></WorkCard>
              ))}
            </WorksContainer>
          </IndexContainer>
          <Footer></Footer>
        </WidthLimiter>
      </MainContents>
    </>
  );
};

export default IndexPage;
